import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const LOGIN_PAGE = '/auth/login';
export const VERIFICATION_PAGE = '/auth/verification';

export const LoginRoute = getExactHiddenRoute(
    'login',
    'menu.login',
    LOGIN_PAGE,
    lazy(() => import('./index')),
    null
);

export const VerificationRoute = getExactHiddenRoute(
    'verification',
    'menu.verification',
    VERIFICATION_PAGE,
    lazy(() => import('./verification')),
    null
);

const AuthRoute = (
    <>
        {LoginRoute}
        {VerificationRoute}
    </>
)

export default AuthRoute;
