import React from 'react';
import {Route} from 'react-router-dom';

import AboutPage from 'pages/about/routes';
import HomePage from 'pages/home/routes';
import PrivacyRoute from 'pages/privacy-policy/routes';
import NotFoundLayout from 'layout/NotFoundLayout';
import ContactsRoute from 'pages/contacts/routes';
import CheckoutRoute from 'pages/checkout/routes';
import AgreementRoute from 'pages/agreement/routes';
import PaymentRoute from 'pages/payment/routes';
import StatusRoute from 'pages/status/routes';
import BarrierRoute from 'pages/barrier/routes';
import AuthRoute from 'pages/auth/routes';
import ProfileRoute from 'pages/profile/routes';
import SubscriptionRoute from 'pages/subscription/routes';
import ReservationRoute from 'pages/reservation/routes';
import PlotRoute from 'pages/plot/routes';
import TermsRoute from './pages/terms-of-use/routes';
import EUSupportRoute from './pages/eu-support/routes';

export const MainRoutes = (
  <>
    {HomePage}
    {AboutPage}
    {PrivacyRoute}
    {ContactsRoute}
    {CheckoutRoute}
    {AgreementRoute}
    {PaymentRoute}
    {StatusRoute}
    {AuthRoute}
    {ProfileRoute}
    {SubscriptionRoute}
    {ReservationRoute}
    {PlotRoute}
    {TermsRoute}
    {EUSupportRoute}
    <Route isHidden path='*' element={<NotFoundLayout/>}/>
  </>
);

export const GateRoutes = (
  <>
    {BarrierRoute}
    <Route isHidden path='*' element={<NotFoundLayout/>}/>
  </>
);

