import {gql} from '@apollo/client';

export const LOGIN_QUERY = gql`
    query Login($password: String!, $username: String!) {
        login(password: $password, username: $username) {
            token
            refreshToken
            user {
                id
            }
        }
    }
`;

export const REFRESH_QUERY = gql`
    query Refresh ($refreshToken: String!) {
        refresh (refreshToken: $refreshToken) {
            token
            refreshToken
        }
    }
`;