export const mapCurrency = currencyIsoCode => {
  const currencies = {
    'EUR': ' €',
  }
  return currencies[currencyIsoCode]
}


export const mapParkingListResponse = response => ({
  id: response.id,
  address: response.name,
  name: response.name,
  position: {
    lat: response.latitude,
    lng: response.longitude,
  },
  hourly: `${response.hourly_price}`,
  daily: `${response.daily_price}`,
  monthly: `${response.monthly_price}`,
  hasGates: response?.gates_aggregate?.aggregate?.count > 0
})

export const mapCars = response => {
  let cars = [];
  response.forEach(el => {
    cars.push(el.car)
  })
  return cars
}

export const mapSettingsResponse = response => ({
  id: response[0]?.id,
  email: response[0]?.email,
  phone: response[0]?.phone,
  manager_email: response[0]?.manager_email,
})