import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const PAGE = '/terms-of-use';

const TermsRoute = getExactHiddenRoute(
  'terms-of-use',
  'menu.contacts',
  PAGE,
  lazy(() => import('./index')),
  null
);

export default TermsRoute;
