import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const PAGE = '/privacy-policy';

const PrivacyRoute = getExactHiddenRoute(
    'privacy-policy',
    'privacy_text',
    PAGE,
    lazy(() => import('./index')),
    null
);

export default PrivacyRoute;
