import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const RESERVATION_PAGE = '/reservation';

const ReservationRoute = getExactHiddenRoute(
  'reservation',
  'menu.reservation',
  RESERVATION_PAGE,
  lazy(() => import('./index')),
  null
);

export default ReservationRoute;
