import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const AGREEMENT_PAGE = '/agreement';

const AgreementRoute = getExactHiddenRoute(
    'agreement',
    'menu.agreement',
    AGREEMENT_PAGE,
    lazy(() => import('./index')),
    null
);

export default AgreementRoute;
