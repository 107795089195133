import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const CONTACTS_PAGE = '/contacts';

const ContactsRoute = getExactHiddenRoute(
    'contacts',
    'menu.contacts',
    CONTACTS_PAGE,
    lazy(() => import('./index')),
    null
);

export default ContactsRoute;
