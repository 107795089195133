import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const CHECKOUT_PAGE = '/checkout';

const CheckoutRoute = getExactHiddenRoute(
    'agreement',
    'menu.checkout',
    CHECKOUT_PAGE,
    lazy(() => import('./index')),
    null
);

export default CheckoutRoute;
