import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const BARRIER_PAGE = '/barrier';
export const RESERVATION_START_PAGE = '/barrier/start';
export const PRE_FINISH_PAGE = '/barrier/pre-finish';
export const FINISH_PAGE = '/barrier/finish';
export const BARRIERS_PAGE = '/barriers';

export const OpenBarrierRoute = getExactHiddenRoute(
  'barrier',
  'menu.barrier',
  BARRIER_PAGE,
  lazy(() => import('./index')),
  null
);

export const ReservationStartRoute = getExactHiddenRoute(
  'reservationStart',
  'menu.reservationStart',
  RESERVATION_START_PAGE,
  lazy(() => import('./start')),
  null
);


export const FinishRoute = getExactHiddenRoute(
  'finish',
  'menu.finish',
  FINISH_PAGE,
  lazy(() => import('./finish')),
  null
);

export const BarriersRoute = getExactHiddenRoute(
  'barriers',
  'menu.barriers',
  BARRIERS_PAGE,
  lazy(() => import('./barriers')),
  null
);

const BarrierRoute = (
  <>
    {ReservationStartRoute}
    {OpenBarrierRoute}
    {FinishRoute}
    {BarriersRoute}
  </>
)

export default BarrierRoute;
