import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const SUCCESS_PAGE = '/success';
export const CANCELLATION_SUCCESS_PAGE = '/cancellation-success';
export const ERROR_PAGE = '/error';
export const NOT_LOGGED_IN_PAGE = '/not-logged-in';
export const MESSAGE_PAGE = '/message';

export const NotLoggedInRoute = getExactHiddenRoute(
  'notLoggedIn',
  'menu.notLoggedIn',
  NOT_LOGGED_IN_PAGE,
  lazy(() => import('./notLoggedIn')),
  null
);

export const SuccessRoute = getExactHiddenRoute(
  'success',
  'menu.success',
  SUCCESS_PAGE,
  lazy(() => import('./success')),
  null
);

export const MessageRoute = getExactHiddenRoute(
  'message',
  'menu.message',
  MESSAGE_PAGE,
  lazy(() => import('./message')),
  null
);

export const CancellationSuccessRoute = getExactHiddenRoute(
  'cancellationSuccess',
  'menu.cancellationSuccess',
  CANCELLATION_SUCCESS_PAGE,
  lazy(() => import('./cancellationSuccess')),
  null
);

export const ErrorRoute = getExactHiddenRoute(
  'error',
  'menu.error',
  ERROR_PAGE,
  lazy(() => import('./error')),
  null
);

const StatusRoute = (
  <>
    {MessageRoute}
    {NotLoggedInRoute}
    {ErrorRoute}
    {SuccessRoute}
    {CancellationSuccessRoute}
  </>
)

export default StatusRoute;
