import './i18n';
import './assets/tailwind.css';
import React from 'react';
import './index.css';
import App from './App';
import {createRoot} from 'react-dom/client';
import GoogleContextProvider from 'context/google';
import {NotificationContextProvider} from 'components/ui-notifications/context';
import {AuthContextProvider} from 'context/auth';
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {CookiesProvider} from 'react-cookie';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {onError} from '@apollo/client/link/error';
import {ApolloLink} from 'apollo-link';
import {REFRESH_QUERY} from './api/graphql/Auth';
import {Observable} from '@apollo/client/core';

const Root = createRoot(document.getElementById('root'));
const APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const httpLink = createHttpLink({
  uri: APP_BACKEND_URL,
});

const errorLink = onError(({graphQLErrors, operation, forward}) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      console.log(err)
      // Check for an authentication error
      if (err.extensions?.code === 'invalid-jwt') {
        // Attempt to fetch a new token
        return new Observable(observer => {
          // Use the Apollo Client instance to perform the refresh query
          client.query({
            query: REFRESH_QUERY,
            variables: {refreshToken: localStorage.getItem('refreshToken')},
            fetchPolicy: 'network-only',
            context: {
              skipAuth: true, // Instructs authLink to skip the Authorization header
            },
          }).then(({data}) => {
            // Extract your new tokens from the response
            const {token, refreshToken} = data.refresh;

            // Update local storage or secure storage with new tokens
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);

            // Update the operation context with the new token
            operation.setContext(({headers = {}}) => ({
              headers: {
                ...headers,
                authorization: `Bearer ${token}`,
              },
            }));

            // Retry the request with the new token
            forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          }).catch(error => {
            console.log(error, 'error')
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');

            // Handle refresh token errors, e.g., logout the user
            observer.error(error);
          });
        });
      }
    }
  }
});

const authLink = setContext((_, {headers, skipAuth}) => {
  if (skipAuth) {
    // Return headers without the Authorization header
    return {
      headers: {
        ...headers,
      },
    };
  }

  const token = localStorage.getItem('token');
  let header = {
    ...headers,
    authorization: token ? `Bearer ${token}` : undefined
  }

  Object.keys(header).forEach(key => header[key] === undefined && delete header[key])

  return {
    headers: {
      ...header
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authLink, errorLink, httpLink]),
});

Root.render(
  <CookiesProvider>
    <NotificationContextProvider>
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <GoogleContextProvider>
            <App/>
          </GoogleContextProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </NotificationContextProvider>
  </CookiesProvider>
);
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
