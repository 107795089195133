import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const USER_SUBSCRIPTION_RENEW_PAGE = '/subscription/user';
export const SUBSCRIPTION_CANCEL_PAGE = '/subscription/cancel';

export const UserSubscriptionRenewRoute = getExactHiddenRoute(
  'user_subscription_renew',
  'menu.user_subscription_renew',
  USER_SUBSCRIPTION_RENEW_PAGE,
  lazy(() => import('./userSubscriptionRenew')),
  null
);


export const SubscriptionCancelRoute = getExactHiddenRoute(
  'subscription_cancel',
  'menu.subscription_cancel',
  SUBSCRIPTION_CANCEL_PAGE,
  lazy(() => import('./subscriptionCancel')),
  null
);

const SubscriptionRoute = (
  <>
    {UserSubscriptionRenewRoute}
    {SubscriptionCancelRoute}
  </>
)

export default SubscriptionRoute;
