import {GateRoutes, MainRoutes} from './Routes';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {GateContextProvider} from './context/gate';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          {MainRoutes}
        </Route>
        <Route path='/gate/*' element={<GateContextProvider><Routes>{GateRoutes}</Routes></GateContextProvider>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
