import useMergeReducer from 'hook/useMergeReducer';
import {createContext, useContext} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {
  isFunction
} from 'crocks';
import {useCookies} from 'react-cookie';
import {expireDate} from '../util/helpers';

/**
 * @typedef {object} AuthContextValue
 * @property {bool} isAuthorized: bool,
 * @property {(newState: object) => void} update
 * @property {(variables: object) => (query: string) => Async} api
 * @property {(query: string) => Async} apiQuery
 */

const AuthContext = createContext();

const AuthContextProvider = ({children}) => {
  const [isAuthorized, setAuthorized] = useState(null);
  const [userData, setUserData] = useState({});
  const [cookies, setCookie] = useCookies(['user']);
  const [activePlate, setActivePlate] = useState(cookies.plate);

  const updatePlate = (v) => {
    setActivePlate(v)
    setCookie('plate', v, {path: '/', expires: expireDate});
  }

  const [state, setState] = useMergeReducer({
    token: null,
    refreshToken: null
  });

  const userId = localStorage.getItem('userId');

  const fail = useCallback(() => {
    setAuthorized(false);
    setState({token: null, refreshToken: null});
  }, [setAuthorized, setState]);

  const success = useCallback(() => {
    setAuthorized(true);
  }, [setAuthorized]);

  const logout = useCallback((callback) => {
    setAuthorized(false);
    setState({token: null, refreshToken: null, user: null});
    setUserData({})
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    isFunction(callback) && callback();
  }, [state, setAuthorized]);

  useEffect(() => {
    if (localStorage.getItem('token') && cookies.phone)
      success();
  }, [state, success]);

  return (
    <AuthContext.Provider value={{
      ...state,
      isAuthorized,
      update: setState,
      logout,
      userData,
      userId,
      setUserData,
      activePlate,
      updatePlate
    }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * @type {() => AuthContextValue}
 * @throws {Error}
 */
const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error('useAuth must be used within a AuthProvider');
  return context;
}

export {
  AuthContextProvider,
  useAuth,
}
