import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const PAYMENT_PAGE = '/payment';

const PaymentRoute = getExactHiddenRoute(
    'payment',
    'menu.payment',
    PAYMENT_PAGE,
    lazy(() => import('./index')),
    null
);

export default PaymentRoute;
