import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const HOME_PAGE = '/';

const HomeRoute = getExactHiddenRoute(
  'home',
  'menu.home',
  HOME_PAGE,
  lazy(() => import('./index')),
  null
);

export default HomeRoute;
