import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const PLOT_PAGE = '/plot';

const PlotRoute = getExactHiddenRoute(
  'plot',
  'menu.plot',
  PLOT_PAGE,
  lazy(() => import('./index')),
  null
);

export default PlotRoute;
