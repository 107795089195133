import {gql} from '@apollo/client';

export const CREATE_ORDER = gql`
    mutation ($objects: [order_insert_input!]!) {
        insert_order(objects: $objects) {
            affected_rows
            returning {
                id
                valid_from
                valid_until
                payment_duration
                cars {
                    car {
                        phone
                        plate
                    }
                }
                orderer {
                    full_name
                    phone
                    address
                    company_id
                }
            }
        }
    }
`

export const GET_ORDERS = gql`
    query GetOrders($_eq: String = "") {
        order_payment(where: {order: {order_users: {user: {phone: {_eq: $_eq}}}, deleted_at: {_is_null: true}}, status: {_eq: "success"}}) {
            amount
            invoice {
                invoice_number
                created_at
                amount
            }
            order {
                price
                parking {
                    full_address
                    daily_price
                    hourly_price
                    monthly_price
                    latitude
                    longitude
                    name
                    currency
                }
                created_at
                cars {
                    car {
                        phone
                        plate
                    }
                }
                id
                payment_duration
                contract_file
                orderer {
                    company {
                        name
                        id
                    }
                    phone
                }
                valid_until
                valid_from
            }
        }
    }
`

export const GET_SUBSCRIPTIONS = gql`
    query GetSubscriptions($_eq: String = "") {
        order_user(where: {phone: {_eq: $_eq}, order: {valid_until: {_gte: "now()"}, payment_status: {_eq: "success"}, deleted_at: {_is_null: true}}}) {
            order {
                parking {
                    id
                    full_address
                    daily_price
                    hourly_price
                    monthly_price
                    latitude
                    longitude
                    name
                    currency
                    gates_aggregate(where: {status: {_eq: "ENABLED"}, type: {_eq: "GATE"}}) {
                        aggregate {
                            count
                        }
                    }
                }
                created_at
                cars {
                    car {
                        phone
                        plate
                    }
                }
                id
                payment_duration
                contract_file
                orderer {
                    company {
                        name
                        id
                    }
                    phone
                }
                paid_until
                valid_until
                valid_from
                price
                extend
                invoice_status
            }
        }
    }
`

export const GET_ORDER_BY_ID = gql`
    query GetOrderByID($id: uuid = "") {
        order_by_pk(id: $id) {
            id
            payment_status
            parking {
                full_address
                daily_price
                hourly_price
                monthly_price
                name
                latitude
                longitude
                id
            }
            valid_from
            valid_until
            payment_duration
            monthly_price
            price
            paid_until
            extend
            cars {
                car {
                    phone
                    plate
                    id
                }
            }
            order_users {
                phone
            }
            orderer{
                id
                email
            }
        }
    }
`

export const UPDATE_ORDER_VALID_UNTIL = gql`
    mutation MyMutation($valid_until: timestamptz, $id: uuid!, $extend: Boolean = false) {
        update_order_by_pk(pk_columns: {id: $id}, _set: {valid_until: $valid_until, extend: $extend}) {
            valid_until
            valid_from
        }
    }
`

export const UPDATE_ORDER_VALID_UNTIL_AND_PRICE = gql`
    mutation UpdateOrderValidUntil($id: uuid!, $valid_until: timestamptz = "", $price: numeric = "", $payment_duration: order_payment_duration_enum = DAILY) {
        update_order_by_pk(pk_columns: {id: $id}, _set: {valid_until: $valid_until, price: $price, payment_duration: $payment_duration}) {
            id
            parking_id
            payment_duration
            price
            valid_from
            valid_until
            orderer_id
        }
    }
`

export const UPDATE_ORDER_PRICE = gql`
    mutation UpdateOrderPrice($id: uuid!, $price: numeric = "") {
        update_order_by_pk(pk_columns: {id: $id}, _set: {price: $price}) {
            id
        }
    }
`

export const UPDATE_ORDERER_EMAIL = gql`
    mutation UpdateOrdererEmail($id: uuid = "", $email: String = "") {
        update_orderer_by_pk(pk_columns: {id: $id}, _set: {email: $email}) {
            id
        }
    }
`

