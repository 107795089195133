import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const ABOUT_PAGE = '/about';

const AboutRoute = getExactHiddenRoute(
    'about',
    'menu.about',
    ABOUT_PAGE,
    lazy(() => import('./index')),
    null
);

export default AboutRoute;
