import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const USER_PROFILE_PAGE = '/profile/user';
export const COMPANY_PROFILE_PAGE = '/profile/company';

export const UserProfileRoute = getExactHiddenRoute(
    'user_profile',
    'menu.user_profile',
    USER_PROFILE_PAGE,
    lazy(() => import('./index')),
    null
);

export const CompanyProfileRoute = getExactHiddenRoute(
    'companyProfile',
    'menu.companyProfile',
    COMPANY_PROFILE_PAGE,
    lazy(() => import('./company')),
    null
);


const ProfileRoute = (
    <>
        {UserProfileRoute}
        {CompanyProfileRoute}
    </>
)

export default ProfileRoute;
