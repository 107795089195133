import {gql} from '@apollo/client';

export const GET_PARKING_LIST = gql`
    query GetParkingList {
        parking {
            id
            name
            monthly_price
            longitude
            latitude
            full_address
            currency
            hourly_price
            daily_price
        }
    }
`

export const GET_BUSY_PARKING_LIST = gql`
    query GetParkingWithActiveOrders($from: timestamptz = "now()", $until: timestamptz = "now()") {
        parking(where: {status: {_eq: "active"}}) {
            id
            size
            name
            monthly_price
            longitude
            latitude
            full_address
            currency
            hourly_price
            daily_price
            gates_aggregate(where: {status: {_eq: "ENABLED"}, type: {_eq: "GATE"}}) {
                aggregate {
                    count
                }
            }
            maxParkingSpots: size
            one_time_orders_aggregate(where: {status: {_eq: "ACTIVE"}, order: {deleted_at: {_is_null: true}}}) {
                aggregate {
                    count
                }
            }
            busyParkingSpots: orders_aggregate(where: {_or: [{valid_until: {_gte: $until}, valid_from: {_lte: $from}}, {valid_until: {_is_null: true}, valid_from: {_lte: $from}}], payment_status: {_eq: "success"}}) {
                aggregate {
                    count
                }
                nodes {
                    order_users {
                        phone
                    }
                }
            }
        }
    }
`

export const GET_BUSY_PARKING_MOBILE_LIST = gql`
    query GetParkingListWithActiveOrders {
        parking(where: {status: {_eq: "active"}}) {
            id
            size
            name
            monthly_price
            longitude
            latitude
            full_address
            currency
            hourly_price
            daily_price
            gates_aggregate(where: {status: {_eq: "ENABLED"}, type: {_eq: "GATE"}}) {
                aggregate {
                    count
                }
            }
            maxParkingSpots: size
            one_time_orders_aggregate(where: {status: {_eq: "ACTIVE"}, order: {deleted_at: {_is_null: true}}}) {
                aggregate {
                    count
                }
            }
            busyParkingSpots: orders_aggregate(where: {_or: [{payment_duration: {_neq: MONTHLY}, valid_until: {_is_null: true}, valid_from: {_lte: "now()"}, one_time_orders: {status: {_eq: "ACTIVE"}}}, {_or: [{valid_until: {_is_null: true}}, {valid_until: {_gte: "now()"}}], payment_status: {_eq: "success"}}]}) {
                aggregate {
                    count
                }
                nodes {
                    order_users {
                        phone
                    }
                }
            }
        }
    }
`

export const GET_PARKING_BY_ID = gql`
    query GetParkingByID($id: uuid = "") {
        parking_by_pk(id: $id) {
            name
            longitude
            latitude
            in_use_count
            hourly_price
            full_address
            daily_price
            currency
            id
            monthly_price
            size
            gates(where: {status: {_eq: "ENABLED"}, type: {_eq: "GATE"}}) {
                name
                id
                is_exit
                uid
                upass
                sequence
                type
            }
        }
    }`

export const GET_BARRIERS = gql`
    query GetBarriers($id: uuid = "") {
        gate(where: {parking_id: {_eq: $id}, type: {_eq: "BARRIER"}}) {
            id
            is_exit
            name
            sequence
            uid
            upass
            type
        }
    }
`


export const GET_PARKING_COUNT_BY_ID = gql`
    query GetParkingWithActiveOrdersById($from: timestamptz = "now()", $until: timestamptz = "now()", $id: uuid = "") {
        parking_by_pk(id: $id) {
            size
            busyParkingSpots: orders_aggregate(where: {_or: [{valid_until: {_gte: $until}, valid_from: {_lte: $from}}, {valid_until: {_is_null: true}, valid_from: {_lte: $from}}], payment_status: {_eq: "success"}}) {
                aggregate {
                    count
                }
                nodes {
                    order_users {
                        phone
                    }
                }
            }
        }
    }

`

export const GET_GATES_BY_ORDER_ID = gql`
    query GetGatesByOrderId($id: uuid = "") {
        order_by_pk(id: $id) {
            id
            parking {
                gates(where: {status: {_eq: "ENABLED"}, type: {_eq: "GATE"}}) {
                    id
                    is_exit
                    name
                    parking_id
                    sequence
                    uid
                    upass
                    type
                }
                id
                one_time_orders(where: {order_id: {_eq: $id}}) {
                    plate
                }
            }
        }
    }
`
