import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';

export const PAGE = '/eu-support';

const EUSupportRoute = getExactHiddenRoute(
  'eu-support',
  'eu_support',
  PAGE,
  lazy(() => import('./index')),
  null
);

export default EUSupportRoute;
